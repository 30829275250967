import styled from 'styled-components';

export const FeatureContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1170px;
  height: auto;
  display: grid;
  grid-template-areas: 'sidebar . main';
  grid-template-columns: 169px 30px 970px;
  grid-auto-rows: auto;
  margin: 0 auto;
  padding-top: 150px;
`;

export const Content = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  margin-bottom: 96px;
  h3 {
    width: 100%;
    height: auto;
    font-style: normal;
    font-weight: bold;
    font-size: 35.16px;
    line-height: 39px;
    color: #2c3641;
    margin-bottom: 40px;
  }
`;

export const Grid = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 40px 32px;
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: auto;
  min-height: 127px;
  margin: 0px;
  
  .feature_header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: static;
    width: 100%;
    height: auto;
    margin-bottom: 8px;
  }
  
  .feature_icon {
    width: auto;
    height: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    margin-right: 10px;
    padding-top: 2px;
    display: grid;
    place-items: center;
    svg {
      color: #1c3fff;
    }
  }
  
  .feature_title {
    max-width: 268.67px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #2c3641;
  }
  
  .feature_description {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: #2c3641;
  }
`;
