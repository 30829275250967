import React from 'react';
import {
  Content,
  Section,
  Grid,
  Feature,
} from '../../styles/Components/FeatureComponent/FeatureComponentStyles';
import OptimizedImage from '../../utils/OptimizedImage';

const Features = ({ featureTypes }) => {
  return (
    <Content>
      {featureTypes &&
        featureTypes.map(({ name, features }) => (
          <Section
            id={name
              .split(' ')
              .join('-')
              .toLowerCase()}
          >
            <h3>{name}</h3>
            <Grid>
              {features.map(feature => (
                <Feature>
                  <div className="feature_header">
                    <span className="feature_icon">
                      {feature.iconImage.image?.gatsbyImageData ? (
                        <OptimizedImage
                          src={feature?.iconImage?.image?.gatsbyImageData}
                          alt={feature?.iconImage?.image?.alt}
                          title={feature?.iconImage?.image?.title}
                        />
                      ) : (
                        <OptimizedImage
                          src={feature.iconImage.image.url}
                          alt={feature?.iconImage?.image?.alt}
                          title={feature?.iconImage?.image?.title}
                        />
                      )}
                    </span>
                    <strong className="feature_title">{feature.heading}</strong>
                  </div>
                  <div className="feature_description">{feature.subhead}</div>
                </Feature>
              ))}
            </Grid>
          </Section>
        ))}
    </Content>
  );
};

export default Features;
